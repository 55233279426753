import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 157] = "BSC_TESTNET";
    ChainId[ChainId["SHIBARIUM"] = 109] = "SHIBARIUM";
    ChainId[ChainId["DOGECHAIN"] = 2000] = "DOGECHAIN";
    ChainId[ChainId["ETH"] = 1] = "ETH";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS_BSC = "0xc7c86B4f940Ff1C13c736b697e3FbA5a6Bc979F9";
export var FACTORY_ADDRESS_BSC_TESTNET = "0xEDedDbde5ffA62545eDF97054edC11013ED72125";
export var FACTORY_ADDRESS_SHIB = "0xEDedDbde5ffA62545eDF97054edC11013ED72125";
export var FACTORY_ADDRESS_DC = "0x7C10a3b7EcD42dd7D79C0b9d58dDB812f92B574A";
export var FACTORY_ADDRESS_ETH = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS_BSC), _define_property(_obj, ChainId.BSC_TESTNET, FACTORY_ADDRESS_BSC_TESTNET), _define_property(_obj, ChainId.SHIBARIUM, FACTORY_ADDRESS_SHIB), _define_property(_obj, ChainId.DOGECHAIN, FACTORY_ADDRESS_DC), _define_property(_obj, ChainId.ETH, FACTORY_ADDRESS_ETH), _obj);
export var INIT_CODE_HASH_BSC = "0x908a679b76436e6d853df6556fbcb55cbfe69fb45a6ac9fe0c1e0f2a53f7baf4";
export var INIT_CODE_HASH_BSC_TESTNET = "0x4455b31327401d7d38602d2b84b8e6a3843289a04fa0ff3ef5195bb4a43f0688";
export var INIT_CODE_HASH_SHIB = "0xa2ef75f706f4aa0c026dd9371dab1bac975a91bc8f0852a86e6f330eedd30848";
export var INIT_CODE_HASH_DC = "0xb336a8b47fdc2600ed0b1be659f72b59cb3096a8dd3814eda610401c00f5fd49";
export var INIT_CODE_HASH_ETH = "0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH_BSC), _define_property(_obj1, ChainId.BSC_TESTNET, INIT_CODE_HASH_BSC_TESTNET), _define_property(_obj1, ChainId.SHIBARIUM, INIT_CODE_HASH_SHIB), _define_property(_obj1, ChainId.DOGECHAIN, INIT_CODE_HASH_DC), _define_property(_obj1, ChainId.ETH, INIT_CODE_HASH_ETH), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
export var WBONE = _define_property({}, ChainId.SHIBARIUM, new Token(ChainId.SHIBARIUM, "0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8", 18, "BONE", "Wrapped BONE", "https://shib.io/"));
export var WETH9 = _define_property({}, ChainId.DOGECHAIN, new Token(ChainId.DOGECHAIN, "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101", 18, "DOGE", "Wrapped DOGE", "https://dogechain.dog/"));
export var WETH = _define_property({}, ChainId.ETH, new Token(ChainId.ETH, "0x9813037ee2218799597d83D4a5B6F3b6778218d9", 18, "ETH", "Ethereum", "https://etherscan.io/"));
var _obj3;
export var WBNB = (_obj3 = {}, _define_property(_obj3, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj3, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0x41c3F37587EBcD46C0F85eF43E38BcfE1E70Ab56", 18, "WBONE", "Puppynet Wrapped BONE", "https://www.binance.org")), _obj3);
var _obj4;
export var WNATIVE = (_obj4 = {}, _define_property(_obj4, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj4, ChainId.BSC_TESTNET, WBNB[ChainId.BSC_TESTNET]), _define_property(_obj4, ChainId.SHIBARIUM, WBONE[ChainId.SHIBARIUM]), _define_property(_obj4, ChainId.DOGECHAIN, WETH9[ChainId.DOGECHAIN]), _define_property(_obj4, ChainId.ETH, WETH[ChainId.ETH]), _obj4);
var _obj5;
export var NATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.BSC, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj5, ChainId.BSC_TESTNET, {
    name: "Puppynet Test BONE",
    symbol: "BONE",
    decimals: 18
}), _define_property(_obj5, ChainId.SHIBARIUM, {
    name: "Shibarium Native Token",
    symbol: "BONE",
    decimals: 18
}), _define_property(_obj5, ChainId.DOGECHAIN, {
    name: "DogeChain Native Token",
    symbol: "DOGE",
    decimals: 18
}), _define_property(_obj5, ChainId.ETH, {
    name: "Ethereum Native Token",
    symbol: "ETH",
    decimals: 18
}), _obj5);
